.smallround {
  background: $drak_gray_semicircle;
  position: absolute;
  border-radius: 50%;
  height: 300px;
  width: 300px;
  left: 30%;
  top: 40%;
}

.contentsds {
  margin: 0 !important;
}

.productbannerleft1 {
  position: relative;
  background: $counter_gray_bg;
}

.detailbigimage {
  height: 150px !important;
  width: 200px !important;
}

.detailbigimageB {
  height: 70vh !important;
  width:100% !important;
}

.productbannerright {
  background: $counter_gray_bg;
  position: relative;
}

.productbannerright1 {
  background: $white_bg;
  position: relative;
}

.logo {
  margin-left: 20%;
}

.productdetailpage-main .contents {
  position: absolute;
  left: -20%;
  background-color: white;
  height: 550px;
  box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
}

.jockey {
  color: #ACACAC;
}

.greenshirt {
  color: gray;
}

.similarproductdiv {
  margin-top: 40%;
}

.sivrediv {
  position: relative;
  margin: 0 !important;
}

.lowerdiv {
  border-radius: 0 0 45% 45%;
  height: 150px;
}

.upperdiv {
  // background-color: rgba(215, 215, 215, 1);
  border-radius: 25% 25% 0 0;
  height: 350px;
  position: relative;
}

.similarproductimg {
  width: 100%;
  background-color: rgba(215, 215, 215, 1);
}

.multi-image {
  width: 150px !important;
  height: 180px !important;
  // box-shadow: 10px 5px 5px black;
}

.oooooo .slick-initialized .slick-slide {
  width: 200px !important;
}

@media screen and (max-width: 1124px) {
  .productbannerleft1 {
    display: none;
  }

  .productbannerright1 {
    left: 0%;
  }

  .productdetailpage-main .contents {
    position: relative;
    left: 0%;
    background-color: white;
    height: auto;
    /* Adjust the height as needed for mobile views */
    box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
  }

  .multi-image {
    width: 20px;
    /* Adjust the width for mobile views */
  }

  .similarproductdiv {
    margin-top: 20px;
    /* Adjust the margin for mobile views */
  }

  .lowerdiv {
    border-radius: 0 0 45% 45%;
    height: auto;
  }

  .upperdiv {
    // background-color: rgba(215, 215, 215, 1);
    border-radius: 25% 25% 0 0;
    height: auto;
    position: relative;
  }

  .similarproductimg {
    position: static;
    /* Remove absolute positioning for mobile views */
    width: 100%;
    /* Adjust the width for mobile views */
    height: auto;
    /* Adjust the height for mobile views */
    background-color: rgba(215, 215, 215, 1);
  }

  
}

@media only screen and (max-width: 767px) {
  .detailbigimageB {
    height: 400px !important;
    width: 100% !important;
  }
}

.modalDisplay{
  // display: contents !important;
}
@media screen and (max-width: 1110px) {}