// body {
//     margin: 0;
//     padding: 0;
//     background: rgba(221, 160, 221, 0.3);
//     background-size: cover;
//   }

.input-group>.form-control{
  border-radius: 0px !important;
}
.ok {
  display: flex !important;
}
.footer-main {
  .footer-left {
    background-color: $banner_bg;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-size {
    padding: 40px 0px 40px 150px !important;
  }
  .footer-right {
    background-color: $light_orange_bg;
    height: 600px;
  }
  .footer-card {
    position: absolute !important;
    background-color: $dark_gray_bg !important;
    color: $white_text;
    width: 800px;
    border-radius: 60px;
    padding: 60px 20px 28px 60px;
    .icon-size-contact {
      width: 44px;
      height: 44px;
    }
    .icon-size {
      width: 48px;
      height: 48px;
      align-items: center;
    }
  }
  .sub-card {
    background-color: $white_bg !important;
    color: $black_text;
    border-radius: 60px;
    width: 500px;
    padding: 20px;
    // color: $white_text;
    // width: 800px;
    //  border-radius: 30px;
  }

  .logo-icon {
    width: 180px;
    height: 100px;
  }
  .horizontal-menu {
    list-style: none;
    padding: 10;
    display: flex; /* Use flexbox to display items in a row */
    position: relative;
    justify-content: space-around;
  }

  .horizontal-menu li {
    // margin-right: 20px; /* Add some spacing between items */
  }
  .horizontal-menu li ul {
    list-style: none;
    display: grid;
    padding: 0px;
  }
  .icon-tringle {
    width: 10px;
    height: 10px;
  }
  .social-icon {
    width: 36px;
    height: 36px;
  }
  .form-control {
    background-color: transparent !important;
    color: $white_text !important;
  }
  .subbgcolor {
    border-radius: 0px !important;
    background-color: $light_orange_bg;
    border: $light_orange_bg;
    color: #fff !important;
    display: inline-block;
    font-weight: 400;
    line-height: 2.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: 40px !important;
  }
  .redheart-icon {
    width: 30px;
    height: 24px;
    padding: 0px 5px 5px 5px;
  }
  li a {
    color: $black_text;
    text-decoration: none;
  }
}
.contactwebcolour a{
  color: #fff;
}
@media (max-width: 768px) {

  .footer-main {
    .footer-left {
      background-color: $banner_bg;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
    }
    .card-size {
      padding: 0px !important;
    }
    .footer-right {
      background-color: $light_orange_bg;
      height: 100vh;
    }
    .footer-card {
      position: relative !important;
      background-color: $dark_gray_bg !important;
      color: $white_text;
      width: 100%;
      border-radius: 0px;
      padding: 10px;
      .icon-size-contact {
        width: 44px;
        height: 44px;
      }
      .icon-size {
        width: 48px;
        height: 48px;
        align-items: center;
      }
    }
    .sub-card {
      background-color: $white_bg !important;
      color: $black_text;
      border-radius: 40px;
      width: 100%;
      padding: 20px;
      // color: $white_text;
      // width: 800px;
      //  border-radius: 30px;
    }

    .logo-icon {
      width: 180px;
      height: 100px;
    }
    .horizontal-menu {
      list-style: none;
      padding: 10;
      display: flex; /* Use flexbox to display items in a row */
      position: relative;
      justify-content: space-around;
    }

    .horizontal-menu li {
      margin-right: 0px; /* Add some spacing between items */
    }
    .horizontal-menu li ul {
      list-style: none;
      display: grid;
      padding: 0px;
    }
    .icon-tringle {
      width: 10px;
      height: 10px;
    }
    .social-icon {
      width: 36px;
      height: 36px;
    }
    .form-control {
      background-color: transparent !important;
      color: $white_text !important;
    }
    .subbgcolor {
      background-color: $light_orange_bg;
      border: $light_orange_bg;
      color: #fff !important;
      display: inline-block;
      font-weight: 400;
      line-height: 2.5;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .redheart-icon {
      width: 30px;
      height: 24px;
      padding: 0px 5px 5px 5px;
    }
    .contact-mob-space {
      padding-left: 10px;
    }
    .mob-quick-link {
      padding-left: 5px;
    }
   
  }
}
.ftbottomsize{
  padding-right: 60px !important;
}

@media screen and (min-width:769px) and (max-width:991px) {
  .footer-main .card-size {
    padding: 200px 0px 40px 0px !important;
}
  
}
.btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 30px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.btn-whatsapp-pulse-border {
	bottom: 120px;
	right: 20px;
	animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
	content: "";
	position: absolute;
	border-radius: 50%;
	padding: 25px;
	border: 5px solid #25d366;
	opacity: 0.75;
	animation-name: pulse-border;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse-border {
	0% {
		padding: 25px;
		opacity: 0.75;
	}
	75% {
		padding: 50px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
